@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
}

i {
    font-size: 60px;
    padding: 0 6px;
}